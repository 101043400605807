<template>
  <NuxtLink
    class="tab"
    :class="{
      'router-link-active': isLinkActive,
      'router-link-active-page': isLinkActive,
    }"
    :to="to"
  >
    <label2 class="cursor-pointer">
      {{ label ?? "Tab" }}
    </label2>
  </NuxtLink>
</template>

<script lang="ts">
export default {
  name: "SalusTab",
  props: {
    label: String,
    type: String,
    size: String,
    to: String,
  },
  computed: {
    isLinkActive() {
      return this.$route.path.split("/sayfa/")[0] === this.to;
    },
  },
};
</script>
<style scoped>
.tab {
  @apply select-none selection:select-none h-auto min-w-fit py-3 bg-transparent text-neutral-500 cursor-pointer active:text-primary-600 active:border-b-2 active:border-secondary-400 hover:border-b-2 hover:border-secondary-400;
}

.tab.router-link-active {
  @apply text-secondary-400 border-b-2 border-secondary-400;
}
</style>
